#map {
  height: 400px;
  background-color: #2a2e35;
  padding: 1rem;
  border-radius: 20px;
  box-shadow: 0 0 8px -4px rgba(0, 0, 0, 0.5);
}
#map .leaflet-container {
  height: 100%;
}
