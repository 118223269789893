.SIR__Graph {
    height: 200px;
}

.container {
    display: flex;
}

.SIR__controls {
    width: 250px;
    display: grid;
    grid-template-columns: 1fr 1fr
}

.formula{
    margin: 10px;
}

.loading__overlay {
    height: 200px;
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-direction: column;
}