* {
  margin: 0;
}

body {
  /* background-color: "#12181B"; */
}

.MuiPaper-root {
  color: none;
  transition: none;
  background-color: none;
}



.app {
  display: flex;
  justify-content: space-evenly;
  padding: 20px;
}

.app__dropdown {
  background-color: #2A2E35;
}

.app__header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  justify-content: space-between;
}

.app__header > h1 {
  color: #fc3c3c;
  font-size: 1.3rem;
}

.app__stats {
  display: flex;
  justify-content: space-between;
  margin-bottom: 16px;
}

.app__left {
  flex: 0.45;
}

.app__right {
  flex: 0.45;
}


.table__header {
  color: #25e358;
}

.app__information > h3 {
  /* color: #6a5d5d; */
  font-weight: 400;
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.app__information > h3:last-of-type {
  margin-top: 1rem;
}

.app__right h3:not(:first-child) {
  margin-top: 20px;
}

.app__right h3 {
  color: #25E35F;
  margin-bottom: 12px;
}

@media (max-width: 990px) {
  .app {
    flex-direction: column;
  }
}

::-webkit-scrollbar {
  width: 1em;
}
 
::-webkit-scrollbar-track {
  box-shadow: inset 0 0 6px rgba(0, 0, 0, 0.3);
}
 
::-webkit-scrollbar-thumb {
  background-color: darkgrey;
  outline: 1px solid slategrey;
}
